@include make-font-face('futura', 300);
@include make-font-face('futura', 400);
@include make-font-face('futura', 600);
@include make-font-face('futura', 700);
@include make-font-face('FuturaNew', 300);
@include make-font-face('FuturaNew', 400);
@include make-font-face('FuturaNew', 500);
@include make-font-face('FuturaNew', 600);
@include make-font-face('FuturaNew', 700);

@include make-font-face('Stinger', 400);
@include make-font-face('Stinger', 700);
@include make-font-face('Stinger', 400, 'italic');
@include make-font-face('Stinger', 700, 'italic');
